<template lang="pug">
div
  om-color-input(
    label="fontColor"
    :property="getPath('fontColor')"
    typeOfComponent="product"
    :onlySolid="true"
  )
  om-color-input(
    label="borderColor"
    :property="getPath('borderColor')"
    typeOfComponent="product"
    :onlySolid="true"
  )
  om-color-input(
    label="backgroundColor"
    :property="getPath('backgroundColor')"
    typeOfComponent="product"
    :onlySolid="true"
  )
</template>

<script>
  import itemMixin from '../../../mixins/item';

  export default {
    mixins: [itemMixin],
    props: {
      subPath: { type: String, default: '' },
      propsProperty: { type: String, default: '' },
    },
  };
</script>
